import dayjs from 'dayjs'
import 'dayjs/locale/ko'

export const preview = (value: string, length: number): string => {
  if (value) {
    const result = value.substring(0, length - length / 2) + '\n' + value.substring(length / 2, length)
    return result.trim()
  } else {
    return ''
  }
}

export const day = (value: Date): string => {
  dayjs.locale('ko')
  if (value)
    return dayjs(value)
      .format('YYYY년 M월 D일 (ddd) HH:mm')
      .toString()
  else return '-'
}

export const date = (value: Date): string => {
  dayjs.locale('ko')
  if (value)
    return dayjs(value)
      .format('YYYY년 M월 D일 (ddd)')
      .toString()
  else return '-'
}
